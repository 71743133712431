import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

//Import Components
import Header from "../components/header"
import Impressum from "../components/impressumBlurb"
import Footer from "../components/footer"

const ImpressumPage = () => (
  <div>
    <Helmet
      title="Alarm Projektwebsite"
    >
        <html lang="de" />
    </Helmet>
    <Header />
    <Impressum />
    <Footer />
  </div>
)

export default ImpressumPage
